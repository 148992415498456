import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class WhatWeDo extends Component {
  state = { x: 0, y: 0 };
  handleMouseMove = (event) => {
    this.setState({
      x: event.clientX,
      y: event.clientY,
    });
  };

  render() {
    const { x, y } = this.state;

    return (
      <div id="container" style={{ backgroundColor: "#f5f5f5" }}>
        <Container id="whatWeDo" onMouseMove={this.handleMouseMove}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content whatWeDoContent"
            >
              <h2>Neler Yaparız;</h2>
              <p>
                Bizim dükkanda her şey bir kahveyle başlar. Kahvemizi içer,
                kahramanınızı sizden dinleriz. Anlatmak istediğiniz hikayeye
                (brif’e) ve hikayeyi kime anlatacağınıza (hedef kitleye) karar
                verdikten sonra, aynı temada anlatılan hikayeleri ve benzer
                hikaye anlatıcılarını (sektör ve rakip) incelenmeye başlarız.
                Daha sonra da bizi en çok heyecanlandıran süreç başlar;
                hünerlerimizi göstermek! Markanızı bir yıldıza dönüştürecek o
                hikayeyi oluşturmak için çalışmaya başlarız. Yıldızların en
                parlak olduğu vakitler, gecelerdir. Markanızın bir yıldız gibi
                parlaması için gece gündüz çalışırız. Sizin için oluşturduğumuz
                farklı alternatiflerin içinden en iyisini birlikte seçer ve
                hedef kitlenize ulaştırırız. Sektörde 15 yılı aşkın
                tecrübemizle, hikayesini yazdığımız yıldızları izlemek bizim
                için bambaşka bir keyif!
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-03.png")}
                alt="Maximum - Daha iyisi mümkün!"
                width="100%"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image whatWeDo screen"
            >
              <Image
                src={require("../../images/cloud.png")}
                alt="Maximum - Daha iyisi mümkün!"
                className="cloud"
                style={{
                  left: x / 25,
                  top: y / 20,
                }}
              />

              <Image
                src={require("../../images/redhat.png")}
                alt="Maximum - Daha iyisi mümkün!"
                className="redhat"
                style={{
                  transform: "rotate(-" + x / 100 + "deg)",
                }}
              />

              <Image
                src={require("../../images/seastars.png")}
                alt="Maximum - Daha iyisi mümkün!"
                className="seastars"
                style={{
                  left: x / 300,
                  top: y / 200,
                }}
              />

              <Image
                src={require("../../images/line-03.png")}
                alt="Maximum - Daha iyisi mümkün!"
                style={{
                  width: 200,
                  position: "absolute",
                  marginTop: 540,
                  marginLeft: -30,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
