import $ from "jquery";

$(document).ready(function () {
  redrawDotNav();

  /* Scroll event handler */
  $(window).bind("scroll", function (e) {
    parallaxScroll();
    redrawDotNav();
  });

  /* Next/prev and primary nav btn click handlers */
  $("a.ourStory").click(function () {
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });
  $("a.whatWeDo").click(function () {
    $("html, body").animate(
      {
        scrollTop: $("#whatWeDo").offset().top,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });
  $("a.heroesOfOurStories").click(function () {
    $("html, body").animate(
      {
        scrollTop: $("#heroesOfOurStories").offset().top,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });
  $("a.meetingPoint").click(function () {
    $("html, body").animate(
      {
        scrollTop: $("#meetingPoint").offset().top,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });

  /* Services */
  $("a.brandCommunicationManagement").click(function () {
    $("html, body").animate(
      {
        scrollTop: $("#brandCommunicationManagement").offset().top,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });

  $("a.graphicDesign").click(function () {
    $("html, body").animate(
      {
        scrollTop: $("#graphicDesign").offset().top,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });

  $("a.production").click(function () {
    $("html, body").animate(
      {
        scrollTop: $("#production").offset().top,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });

  $("a.digitalMarketing").click(function () {
    $("html, body").animate(
      {
        scrollTop: $("#digitalMarketing").offset().top,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });

  $("a.socialMediaManagement").click(function () {
    $("html, body").animate(
      {
        scrollTop: $("#socialMediaManagement").offset().top,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });

  $("a.webSiteServices").click(function () {
    $("html, body").animate(
      {
        scrollTop: $("#webSiteServices").offset().top,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });

  $("a.brandRegistration").click(function () {
    $("html, body").animate(
      {
        scrollTop: $("#brandRegistration").offset().top,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });

  $("a.publishingService").click(function () {
    $("html, body").animate(
      {
        scrollTop: $("#publishingService").offset().top,
      },
      1000,
      function () {
        parallaxScroll(); // Callback is required for iOS
      }
    );
    return false;
  });
});

function parallaxScroll() {
  var scrolled = $(window).scrollTop();
  $("#ourStory").css("top", 0 - scrolled * 1 + "px");
  $("#whatWeDo").css("top", 0 - scrolled * 1 + "px");
  $("#brandCommunicationManagement").css("top", 0 - scrolled * 1 + "px");
  $("#graphicDesign").css("top", 0 - scrolled * 1 + "px");
  $("#production").css("top", 0 - scrolled * 1 + "px");
  $("#digitalMarketing").css("top", 0 - scrolled * 1 + "px");
  $("#socialMediaManagement").css("top", 0 - scrolled * 1 + "px");
  $("#webSiteServices").css("top", 0 - scrolled * 1 + "px");
  $("#brandRegistration").css("top", 0 - scrolled * 1 + "px");
  $("#publishingService").css("top", 0 - scrolled * 1 + "px");
  $("#heroesOfOurStories").css("top", 0 - scrolled * 1 + "px");
  $("#meetingPoint").css("top", 0 - scrolled * 1 + "px");
}

var isDuraction = false;

$(window).bind("scroll", function (e) {
  if ($(window).scrollTop() > 1800 && !isDuraction) {
    isDuraction = true;
    $("img.superman").animate(
      {
        marginLeft: "+=138%",
      },
      1000,
      function () {
        //$( this ).stop();
      }
    );
  }
});

/* Set navigation dots to an active state as the user scrolls */
function redrawDotNav() {
  // The top of each section is offset by half the distance to the previous section.
  //var section1Top =  $('#ourStory').offset().top - (($('#whatWeDo').offset().top - $('#ourStory').offset().top) / 2);
  var section2Top =
    $("#whatWeDo").offset().top -
    ($("#heroesOfOurStories").offset().top - $("#whatWeDo").offset().top) / 2;
  var section3Top =
    $("#heroesOfOurStories").offset().top -
    ($("#meetingPoint").offset().top - $("#heroesOfOurStories").offset().top) /
      2;
  var section4Top =
    $("#meetingPoint").offset().top -
    ($(document).height() - $("#meetingPoint").offset().top) / 2;

  $(".main-menu a").removeClass("active");

  if ($(document).scrollTop() < 1200) {
    $(".main-menu a.ourStory").addClass("active");
  } else if (
    $(document).scrollTop() >= section2Top &&
    $(document).scrollTop() < section3Top
  ) {
    $(".main-menu a.whatWeDo").addClass("active");
  } else if (
    $(document).scrollTop() >= section3Top &&
    $(document).scrollTop() < section4Top
  ) {
    $(".main-menu a.heroesOfOurStories").addClass("active");
  } else if ($(document).scrollTop() >= section4Top) {
    $(".main-menu a.meetingPoint").addClass("active");
  }
}
