import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class BrandRegistration extends Component {
  render() {
    return (
      <div id="container">
        <Container id="brandRegistration">
          <Row>
            <Col xs={12} sm={12} md={12} lg={4} className="content mobile">
              <h2>Marka Tescili;</h2>
              <p>
                Konusu ne olursa olsun her hikaye özeldir. Sizin hikayenizin
                kahramanı da özel olsun. Marka tescil başvurusu, marka izleme,
                marka yenileme gibi ihtiyaçlarınızı karşılayalım; kahramanınızın
                yıldızı hep parlasın.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-10.png")}
                alt="Marka Tescili"
                width="100%"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image brandRegistration screen"
            >
              <Image
                src={require("../../images/line-10.png")}
                alt="Marka Tescili"
                style={{
                  width: 180,
                  position: "absolute",
                  marginTop: 520,
                  marginLeft: 250,
                }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content screen"
              style={{ paddingTop: 200, textAlign: "right" }}
            >
              <h2>Marka Tescili;</h2>
              <p>
                Konusu ne olursa olsun her hikaye özeldir. Sizin hikayenizin
                kahramanı da özel olsun. Marka tescil başvurusu, marka izleme,
                marka yenileme gibi ihtiyaçlarınızı karşılayalım; kahramanınızın
                yıldızı hep parlasın.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
