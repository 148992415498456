import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class GraphicDesign extends Component {
  state = { x: 0, y: 0 };
  handleMouseMove = (event) => {
    this.setState({
      x: event.clientX,
      y: event.clientY,
    });
  };

  render() {
    const { x, y } = this.state;

    return (
      <div id="container" style={{ backgroundColor: "#f5f5f5" }}>
        <Container id="graphicDesign" onMouseMove={this.handleMouseMove}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content graphicDesignContent"
            >
              <h2>Grafik Tasarım;</h2>
              <p>
                Sadece kelimeler değil, renkler ve çizgiler de hikayeler
                anlatır. Hikayenizin kahramanını kelimelerin ötesine taşımak
                isterseniz; global tasarım trendlerine ve markanızın kimliğine
                uygun grafik tasarım çalışmalarıyla markanızı parlatalım.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-05.png")}
                alt="Grafik Tasarım"
                width="100%"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image graphicDesign screen"
            >
              <Image
                src={require("../../images/cloud-2.png")}
                alt="Grafik Tasarım"
                className="cloud"
                style={{
                  left: x / 15,
                  top: y / 50,
                }}
              />

              <Image
                src={require("../../images/tree.png")}
                alt="Grafik Tasarım"
                className="tree"
                style={{
                  left: x / 150,
                  top: y / 200,
                }}
              />

              <Image
                src={require("../../images/graphic-design-font.png")}
                alt="Grafik Tasarım"
                className="font"
              />

              <Image
                src={require("../../images/line-05.png")}
                alt="Grafik Tasarım"
                style={{
                  width: 250,
                  position: "absolute",
                  marginTop: 520,
                  marginLeft: 30,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
