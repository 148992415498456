import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class WithYouOur extends Component {
  state = { isActive: false };

  render() {
    const man = require("../../images/Sizinle_Bizim_Man.png");
    const manOver = require("../../images/Sizinle_Bizim_Man_Hover.png");
    const { isActive } = this.state;

    return (
      <div id="container">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={4} className="content mobile">
              <h2>Sizinle bizim;</h2>
              <p>
                Markanızla da hedef kitlesinin arasında uzun yıllar sürecek
                dostluklar kurulması için uğraşırız.
              </p>

              <p>
                Tanıştığımız her kahramanı, yıldıza dönüştüren hikayeler
                yaratmak için çalışırız.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-02.png")}
                alt="Maximum - Daha iyisi mümkün!"
                width="100%"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image withYouOur screen"
              onMouseMove={() => this.setState({ isActive: true })}
              onMouseLeave={() => this.setState({ isActive: false })}
            >
              <Image src={isActive ? manOver : man} alt="" className="man" />

              <Image
                src={require("../../images/line-02.png")}
                alt="Maximum - Daha iyisi mümkün!"
                style={{
                  width: 200,
                  position: "absolute",
                  marginTop: 460,
                  marginLeft: 400,
                }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content withYouOurContent screen"
            >
              <h2>Sizinle bizim;</h2>
              <p>
                markanızla da hedef kitlesinin arasında uzun yıllar sürecek
                dostluklar kurulması için uğraşırız.
              </p>

              <p>
                Tanıştığımız her kahramanı, yıldıza dönüştüren hikayeler
                yaratmak için çalışırız.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
