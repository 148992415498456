import React, { Component } from "react";

/* Components */
import OurStory from "./pages/our-story";
import WithYouOur from "./pages/with-you-our";
import WhatWeDo from "./pages/what-we-do";
import BrandCommunicationManagement from "./pages/brand-communication-management";
import GraphicDesign from "./pages/graphic-design";
import Production from "./pages/production";
import DigitalMarketing from "./pages/digital-marketing";
import SocialMediaManagement from "./pages/social-media-management";
import WebSiteServices from "./pages/web-site-services";
import BrandRegistration from "./pages/brand-registration";
import HeroesOfOurStories from "./pages/heroes-of-our-stories";
import Referances from "./pages/referances";
import PublishingServices from "./pages/publishing-services";
import MeetingPoint from "./pages/meeting-point";
import Map from "./pages/map";

export default class Pages extends Component {
  render() {
    return (
      <div id="main" style={{ overflowX: "hidden" }}>
        <OurStory />
        <WithYouOur />
        <WhatWeDo />
        <BrandCommunicationManagement />
        <GraphicDesign />
        <Production />
        <DigitalMarketing />
        <SocialMediaManagement />
        <WebSiteServices />
        <BrandRegistration />
        <PublishingServices />
        <HeroesOfOurStories />
        <MeetingPoint />
        <Referances />
        <Map />
      </div>
    );
  }
}
