import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class Production extends Component {
  state = { x: 0 };
  handleMouseMove = (event) => {
    this.setState({
      x: 1500 - event.clientX,
    });
  };

  render() {
    const { x } = this.state;

    return (
      <div id="container">
        <Container id="production" onMouseMove={this.handleMouseMove}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={4} className="content mobile">
              <h2>Prodüksiyon;</h2>
              <p>
                Kahramanınızın macerasını; en güçlü satış ve marka kimliği
                oluşturma mecralarına taşımak isterseniz, sizin için günümüzün
                iki ekranının da dinamiklerine uygun hikayeler yaratabiliriz.
                Hem televizyonun hem de dijitalin gücünü arkanıza alarak
                hikayenizin hatırlanabilirliğini artıralım.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-06.png")}
                alt="Prodüksiyon"
                width="100%"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image production screen"
            >
              <Image
                src={require("../../images/lamb.png")}
                alt="Prodüksiyon"
                className="lamb"
                style={{
                  transform: "rotate(-" + x / 50 + "deg)",
                }}
              />
              <Image
                src={require("../../images/camera.png")}
                alt="Prodüksiyon"
                className="camera"
              />
              <div
                className="blur"
                style={{ marginTop: 260, marginLeft: "14%", opacity: x / 1000 }}
              ></div>

              <Image
                src={require("../../images/line-06.png")}
                alt="Prodüksiyon"
                style={{
                  width: 120,
                  position: "absolute",
                  marginTop: 550,
                  marginLeft: 480,
                }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content screen"
              style={{ textAlign: "right", marginTop: 200 }}
            >
              <h2>Prodüksiyon;</h2>
              <p>
                Kahramanınızın macerasını; en güçlü satış ve marka kimliği
                oluşturma mecralarına taşımak isterseniz, sizin için günümüzün
                iki ekranının da dinamiklerine uygun hikayeler yaratabiliriz.
                Hem televizyonun hem de dijitalin gücünü arkanıza alarak
                hikayenizin hatırlanabilirliğini artıralım.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
