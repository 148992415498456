import React, { Component } from 'react';
import { Container, Row, Col, Image, Carousel } from 'react-bootstrap';

export default class GraphicDesign extends Component {

    render() {

        return (
            <div id="container">
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="content" style={{textAlign:'center'}}>
                            <h2>İşte en değerli hikayelerin başrolleri;</h2>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} className="referances screen">
                          <Carousel controls={true} keyboard={true} touch={true} interval={10000}>
                            <Carousel.Item>
                              <Image src={require('../../images/referances/01.png')} alt="" />
                              <Image src={require('../../images/referances/02.png')} alt="" />
                              <Image src={require('../../images/referances/03.png')} alt="" />
                              <Image src={require('../../images/referances/04.png')} alt="" />
                              <Image src={require('../../images/referances/05.png')} alt="" />
                              <Image src={require('../../images/referances/06.jpg')} alt="" />
                              <Image src={require('../../images/referances/07.png')} alt="" />
                              <Image src={require('../../images/referances/08.png')} alt="" />
                              <Image src={require('../../images/referances/09.png')} alt="" />
                              <Image src={require('../../images/referances/10.png')} alt="" />
                              <Image src={require('../../images/referances/11.png')} alt="" />
                              <Image src={require('../../images/referances/12.png')} alt="" />
                              <Image src={require('../../images/referances/13.png')} alt="" />
                              <Image src={require('../../images/referances/14.png')} alt="" />
                              <Image src={require('../../images/referances/15.png')} alt="" />
                              <Image src={require('../../images/referances/16.png')} alt="" />
                              <Image src={require('../../images/referances/17.png')} alt="" />
                              <Image src={require('../../images/referances/18.png')} alt="" />
                              <Image src={require('../../images/referances/19.png')} alt="" />
                              <Image src={require('../../images/referances/20.png')} alt="" />
                              <Image src={require('../../images/referances/21.png')} alt="" />
                            </Carousel.Item>
                            <Carousel.Item>
                              <Image src={require('../../images/referances/22.png')} alt="" />
                              <Image src={require('../../images/referances/23.png')} alt="" />
                              <Image src={require('../../images/referances/24.png')} alt="" />
                              <Image src={require('../../images/referances/25.png')} alt="" />
                              <Image src={require('../../images/referances/26.png')} alt="" />
                              <Image src={require('../../images/referances/27.png')} alt="" />
                              <Image src={require('../../images/referances/28.png')} alt="" />
                              <Image src={require('../../images/referances/29.png')} alt="" />
                              <Image src={require('../../images/referances/30.png')} alt="" />
                              <Image src={require('../../images/referances/31.png')} alt="" />
                              <Image src={require('../../images/referances/32.png')} alt="" />
                              <Image src={require('../../images/referances/33.png')} alt="" />
                              <Image src={require('../../images/referances/34.png')} alt="" />
                              <Image src={require('../../images/referances/35.png')} alt="" />
                              <Image src={require('../../images/referances/36.png')} alt="" />
                              <Image src={require('../../images/referances/37.png')} alt="" />
                              <Image src={require('../../images/referances/38.png')} alt="" />
                              <Image src={require('../../images/referances/39.png')} alt="" />
                              <Image src={require('../../images/referances/40.png')} alt="" />
                              <Image src={require('../../images/referances/41.png')} alt="" />
                              <Image src={require('../../images/referances/42.png')} alt="" />
                            </Carousel.Item>
                            <Carousel.Item>
                              <Image src={require('../../images/referances/43.png')} alt="" />
                              <Image src={require('../../images/referances/44.png')} alt="" />
                              <Image src={require('../../images/referances/45.png')} alt="" />
                              <Image src={require('../../images/referances/46.png')} alt="" />
                              <Image src={require('../../images/referances/47.png')} alt="" />
                              <Image src={require('../../images/referances/48.png')} alt="" />
                              <Image src={require('../../images/referances/49.png')} alt="" />
                              <Image src={require('../../images/referances/50.png')} alt="" />
                              <Image src={require('../../images/referances/51.png')} alt="" />
                              <Image src={require('../../images/referances/52.png')} alt="" />
                              <Image src={require('../../images/referances/53.png')} alt="" />
                              <Image src={require('../../images/referances/54.png')} alt="" />
                              <Image src={require('../../images/referances/55.png')} alt="" />
                              <Image src={require('../../images/referances/56.png')} alt="" />
                              <Image src={require('../../images/referances/57.png')} alt="" />
                              <Image src={require('../../images/referances/58.png')} alt="" />
                              <Image src={require('../../images/referances/59.png')} alt="" />
                              <Image src={require('../../images/referances/60.png')} alt="" />
                              <Image src={require('../../images/referances/61.png')} alt="" />
                              <Image src={require('../../images/referances/62.png')} alt="" />
                              <Image src={require('../../images/referances/63.png')} alt="" />
                            </Carousel.Item>
                            <Carousel.Item>
                              <Image src={require('../../images/referances/64.png')} alt="" />
                              <Image src={require('../../images/referances/65.png')} alt="" />
                              <Image src={require('../../images/referances/66.png')} alt="" />
                              <Image src={require('../../images/referances/67.png')} alt="" />
                              <Image src={require('../../images/referances/68.png')} alt="" />
                              <Image src={require('../../images/referances/69.png')} alt="" />
                              <Image src={require('../../images/referances/70.png')} alt="" />
                              <Image src={require('../../images/referances/71.png')} alt="" />
                              <Image src={require('../../images/referances/72.png')} alt="" />
                              <Image src={require('../../images/referances/73.png')} alt="" />
                              <Image src={require('../../images/referances/74.png')} alt="" />
                              <Image src={require('../../images/referances/75.png')} alt="" />
                              <Image src={require('../../images/referances/76.png')} alt="" />
                              <Image src={require('../../images/referances/77.png')} alt="" />
                              <Image src={require('../../images/referances/78.png')} alt="" />
                              <Image src={require('../../images/referances/79.png')} alt="" />
                              <Image src={require('../../images/referances/80.png')} alt="" />
                              <Image src={require('../../images/referances/81.png')} alt="" />
                              <Image src={require('../../images/referances/82.png')} alt="" />
                              <Image src={require('../../images/referances/83.png')} alt="" />
                              <Image src={require('../../images/referances/84.png')} alt="" />
                            </Carousel.Item>
                          </Carousel>
                          </Col>

                          <Col xs={12} sm={12} md={12} lg={12} className="referances mobile">
                            <Carousel controls={true} keyboard={true} touch={true} interval={10000}>
                              <Carousel.Item>
                                <Image src={require('../../images/referances/01.png')} alt="" />
                                <Image src={require('../../images/referances/02.png')} alt="" />
                                <Image src={require('../../images/referances/03.png')} alt="" />
                                <Image src={require('../../images/referances/04.png')} alt="" />
                                <Image src={require('../../images/referances/05.png')} alt="" />
                                <Image src={require('../../images/referances/06.jpg')} alt="" />
                                <Image src={require('../../images/referances/07.png')} alt="" />
                                <Image src={require('../../images/referances/08.png')} alt="" />
                                <Image src={require('../../images/referances/09.png')} alt="" />
                              </Carousel.Item>
                              <Carousel.Item>
                                <Image src={require('../../images/referances/10.png')} alt="" />
                                <Image src={require('../../images/referances/11.png')} alt="" />
                                <Image src={require('../../images/referances/12.png')} alt="" />
                                <Image src={require('../../images/referances/13.png')} alt="" />
                                <Image src={require('../../images/referances/14.png')} alt="" />
                                <Image src={require('../../images/referances/15.png')} alt="" />
                                <Image src={require('../../images/referances/16.png')} alt="" />
                                <Image src={require('../../images/referances/17.png')} alt="" />
                                <Image src={require('../../images/referances/18.png')} alt="" />
                              </Carousel.Item>
                              <Carousel.Item>
                                <Image src={require('../../images/referances/19.png')} alt="" />
                                <Image src={require('../../images/referances/20.png')} alt="" />
                                <Image src={require('../../images/referances/21.png')} alt="" />
                                <Image src={require('../../images/referances/22.png')} alt="" />
                                <Image src={require('../../images/referances/23.png')} alt="" />
                                <Image src={require('../../images/referances/24.png')} alt="" />
                                <Image src={require('../../images/referances/25.png')} alt="" />
                                <Image src={require('../../images/referances/26.png')} alt="" />
                                <Image src={require('../../images/referances/27.png')} alt="" />
                              </Carousel.Item>
                              <Carousel.Item>
                                <Image src={require('../../images/referances/28.png')} alt="" />
                                <Image src={require('../../images/referances/29.png')} alt="" />
                                <Image src={require('../../images/referances/30.png')} alt="" />
                                <Image src={require('../../images/referances/31.png')} alt="" />
                                <Image src={require('../../images/referances/32.png')} alt="" />
                                <Image src={require('../../images/referances/33.png')} alt="" />
                                <Image src={require('../../images/referances/34.png')} alt="" />
                                <Image src={require('../../images/referances/35.png')} alt="" />
                                <Image src={require('../../images/referances/36.png')} alt="" />
                              </Carousel.Item>
                              <Carousel.Item>
                                <Image src={require('../../images/referances/37.png')} alt="" />
                                <Image src={require('../../images/referances/38.png')} alt="" />
                                <Image src={require('../../images/referances/39.png')} alt="" />
                                <Image src={require('../../images/referances/40.png')} alt="" />
                                <Image src={require('../../images/referances/41.png')} alt="" />
                                <Image src={require('../../images/referances/42.png')} alt="" />
                                <Image src={require('../../images/referances/43.png')} alt="" />
                                <Image src={require('../../images/referances/44.png')} alt="" />
                                <Image src={require('../../images/referances/45.png')} alt="" />
                              </Carousel.Item>
                              <Carousel.Item>
                                <Image src={require('../../images/referances/46.png')} alt="" />
                                <Image src={require('../../images/referances/47.png')} alt="" />
                                <Image src={require('../../images/referances/48.png')} alt="" />
                                <Image src={require('../../images/referances/49.png')} alt="" />
                                <Image src={require('../../images/referances/50.png')} alt="" />
                                <Image src={require('../../images/referances/51.png')} alt="" />
                                <Image src={require('../../images/referances/52.png')} alt="" />
                                <Image src={require('../../images/referances/53.png')} alt="" />
                                <Image src={require('../../images/referances/54.png')} alt="" />
                              </Carousel.Item>
                              <Carousel.Item>
                                <Image src={require('../../images/referances/55.png')} alt="" />
                                <Image src={require('../../images/referances/56.png')} alt="" />
                                <Image src={require('../../images/referances/57.png')} alt="" />
                                <Image src={require('../../images/referances/58.png')} alt="" />
                                <Image src={require('../../images/referances/59.png')} alt="" />
                                <Image src={require('../../images/referances/60.png')} alt="" />
                                <Image src={require('../../images/referances/61.png')} alt="" />
                                <Image src={require('../../images/referances/62.png')} alt="" />
                                <Image src={require('../../images/referances/63.png')} alt="" />
                              </Carousel.Item>
                              <Carousel.Item>
                                <Image src={require('../../images/referances/64.png')} alt="" />
                                <Image src={require('../../images/referances/65.png')} alt="" />
                                <Image src={require('../../images/referances/66.png')} alt="" />
                                <Image src={require('../../images/referances/67.png')} alt="" />
                                <Image src={require('../../images/referances/68.png')} alt="" />
                                <Image src={require('../../images/referances/69.png')} alt="" />
                                <Image src={require('../../images/referances/70.png')} alt="" />
                                <Image src={require('../../images/referances/71.png')} alt="" />
                                <Image src={require('../../images/referances/72.png')} alt="" />
                              </Carousel.Item>
                              <Carousel.Item>
                                <Image src={require('../../images/referances/73.png')} alt="" />
                                <Image src={require('../../images/referances/74.png')} alt="" />
                                <Image src={require('../../images/referances/75.png')} alt="" />
                                <Image src={require('../../images/referances/76.png')} alt="" />
                                <Image src={require('../../images/referances/77.png')} alt="" />
                                <Image src={require('../../images/referances/78.png')} alt="" />
                                <Image src={require('../../images/referances/79.png')} alt="" />
                                <Image src={require('../../images/referances/81.png')} alt="" />
                                <Image src={require('../../images/referances/82.png')} alt="" />
                              </Carousel.Item>
                            </Carousel>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
