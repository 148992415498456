import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class SocialMediaManagement extends Component {
  render() {
    return (
      <div id="container">
        <Container id="socialMediaManagement">
          <Row>
            <Col xs={12} sm={12} md={12} lg={4} className="content mobile">
              <h2>Sosyal Medya Yönetimi;</h2>
              <p>
                İyi hikayelerin, takipçileri vardır. Sizin için tasarladığımız
                iyi hikayeler de kahramanınızın arkasından takipçilerini
                sürükleyecektir. Kahramanınız sosyal medyada var olsun ki; takip
                edilsin, beğenilsin, paylaşılsın ve geniş kitlelerle tanışsın.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-08.png")}
                alt="Sosyal Medya Yönetimi"
                width="100%"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image socialMediaManagement screen"
            >
              <Image
                src={require("../../images/line-08.png")}
                alt="Sosyal Medya Yönetimi"
                style={{
                  width: 200,
                  position: "absolute",
                  marginTop: 550,
                  marginLeft: 320,
                }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content screen"
              style={{ paddingTop: 200, textAlign: "right" }}
            >
              <h2>Sosyal Medya Yönetimi;</h2>
              <p>
                İyi hikayelerin, takipçileri vardır. Sizin için tasarladığımız
                iyi hikayeler de kahramanınızın arkasından takipçilerini
                sürükleyecektir. Kahramanınız sosyal medyada var olsun ki; takip
                edilsin, beğenilsin, paylaşılsın ve geniş kitlelerle tanışsın.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
