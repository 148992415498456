import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class BrandCommunicationManagement extends Component {
  render() {
    return (
      <div id="container">
        <Container id="brandCommunicationManagement">
          <Row>
            <Col xs={12} sm={12} md={12} lg={4} className="content mobile">
              <h2>Marka İletişimi Yönetimi;</h2>
              <p>
                Reklam Stratejileri; Her kahramanın bir hikayesi her hikayenin
                de bir teması vardır. Bize göre; doğru temayla anlatılan
                hikayeler, markaları birer yıldız gibi parlatır. Doğru bir
                iletişim stratejisiyle, sizin markanızı da bir yıldız gibi
                parlatalım.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-04.png")}
                alt="Marka İletişimi Yönetimi"
                width="100%"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image brandCommunicationManagement screen"
            >
              <Image
                src={require("../../images/superman.png")}
                alt="Marka İletişimi Yönetimi"
                className="superman"
              />

              <Image
                src={require("../../images/line-04.png")}
                alt="Marka İletişimi Yönetimi"
                style={{
                  width: 100,
                  position: "absolute",
                  marginTop: 480,
                  marginLeft: 450,
                }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content screen"
              style={{ textAlign: "right", marginTop: 200 }}
            >
              <h2>Marka İletişimi Yönetimi;</h2>
              <p>
                Reklam Stratejileri; Her kahramanın bir hikayesi her hikayenin
                de bir teması vardır. Bize göre; doğru temayla anlatılan
                hikayeler, markaları birer yıldız gibi parlatır. Doğru bir
                iletişim stratejisiyle, sizin markanızı da bir yıldız gibi
                parlatalım.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
