import React, { Component } from "react";
import { Container, Navbar, Nav, Image } from "react-bootstrap";

import "../utils/parallax.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/style.css";

export default class Header extends Component {
  render() {
    return (
      <Container
        style={{
          minWidth: "100%",
          boxShadow: "0px 0px 10px rgba(51,51,51,.1)",
          backgroundColor: "#fff",
          position: "fixed",
          zIndex: 9,
          top: 0,
        }}
      >
        <Navbar expand="lg" id="header">
          <Navbar.Brand>
            <Nav.Link className="ourStory">
              <Image
                src={require("../images/logo.png")}
                className="logo"
                height="40"
              />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto main-menu">
              <Nav.Link className="ourStory">BİZİM HİKAYEMİZ</Nav.Link>
              <Nav.Link className="whatWeDo">
                NE YAPARIZ
                <ul className="submenu">
                  <li>
                    <Nav.Link className="brandCommunicationManagement">
                      MARKA İLETİŞİM YÖNETİMİ
                    </Nav.Link>
                  </li>
                  <li>
                    <Nav.Link className="graphicDesign">
                      GRAFİK TASARIM
                    </Nav.Link>
                  </li>
                  <li>
                    <Nav.Link className="production">PRODÜKSİYON</Nav.Link>
                  </li>
                  <li>
                    <Nav.Link className="digitalMarketing">
                      DİJİTAL PAZARLAMA
                    </Nav.Link>
                  </li>
                  <li>
                    <Nav.Link className="socialMediaManagement">
                      SOSYAL MEDYA YÖNETİMİ
                    </Nav.Link>
                  </li>
                  <li>
                    <Nav.Link className="webSiteServices">
                      WEB SİTE HİZMETLERİ
                    </Nav.Link>
                  </li>
                  <li>
                    <Nav.Link className="brandRegistration">
                      MARKA TESCİLİ
                    </Nav.Link>
                  </li>
                  <li>
                    <Nav.Link className="publishingService">
                      YAYINCILIK HİZMETLERİ
                    </Nav.Link>
                  </li>
                </ul>
              </Nav.Link>
              <Nav.Link className="heroesOfOurStories">
                HİKAYEMİZİN KAHRAMANLARI
              </Nav.Link>
              <Nav.Link className="meetingPoint">BULUŞMA NOKTAMIZ</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    );
  }
}
