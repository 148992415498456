import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/style.css";

export default class Footer extends Component {
    render() {
        return (
          <div id="footer">
            <Container>
              <Row>
              <Col xs={12} sm={12} md={6} lg={6} className="copyright social mobile">
                <a href="https://www.facebook.com/MaximumBC/" target="_blank"><Image src={require('../images/facebook.png')} alt="" /></a>
                <a href="https://www.instagram.com/maximumbcom/" target="_blank"><Image src={require('../images/instagram.png')} alt="" /></a>
                <a href="https://twitter.com/maximumbc" target="_blank"><Image src={require('../images/twitter.png')} alt="" /></a>
                <p style={{marginTop:20}}>&copy; 2020 Maximum Brand Communication.<br/>All rights reserved.</p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="copyright screen">
                &copy; 2020 Maximum Brand Communication. All rights reserved.
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="social screen">
                <a href="https://www.facebook.com/MaximumBC/" target="_blank"><Image src={require('../images/facebook.png')} alt="" /></a>
                <a href="https://www.instagram.com/maximumbcom/" target="_blank"><Image src={require('../images/instagram.png')} alt="" /></a>
                <a href="https://twitter.com/maximumbc" target="_blank"><Image src={require('../images/twitter.png')} alt="" /></a>
              </Col>
              </Row>
            </Container>
            </div>
        )
    }
}
