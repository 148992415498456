import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class DigitalMarketing extends Component {
  state = { x: 0 };
  handleMouseMove = (event) => {
    this.setState({
      x: event.clientX,
    });
  };

  render() {
    const { x } = this.state;

    return (
      <div id="container" style={{ backgroundColor: "#f5f5f5" }}>
        <Container id="digitalMarketing" onMouseMove={this.handleMouseMove}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content digitalMarketingContent"
            >
              <h2>Dijital Pazarlama;</h2>
              <p>
                Hikayenizi duyurmak istediğiniz herkes vaktinin çoğunu dijitalde
                geçiriyor. Aynı zamanda rakipleriniz de. Siz kime ulaşmak
                istediğinizi söyleyin; biz kahramanınızın onlara ulaşması için
                gereken hizmeti sunalım.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-07.png")}
                alt="Dijital Pazarlama"
                width="100%"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image digitalMarketing screen"
            >
              <Image
                src={require("../../images/star.png")}
                alt="Dijital Pazarlama"
                className="star"
                style={{
                  WebkitTransform: "rotate(" + x + "deg)",
                }}
              />

              <Image
                src={require("../../images/line-07.png")}
                alt="Dijital Pazarlama"
                style={{
                  width: 200,
                  position: "absolute",
                  marginTop: 550,
                  marginLeft: 0,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
