import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default class Map extends Component {
  static defaultProps = {
    center: {
      lat: 40.9234491,
      lng: 29.4155563
    },
    zoom: 15
  };


    render() {
      const {zoom, center} = this.props;

        return (
            <div id="map">
                  <div className="contact">
                    <a href="http://maps.google.com/?q=Akfırat,+28.+Sk.+No:3,+34959+Tuzla%2Fİstanbul" target="_blank">
                        Akfırat Mah. 28. Sok.<br/>
                        No:3/1 Pk.:34959<br/>
                        Tuzla - İstanbul</a>

                        <a href="mailto:info@maximumbc.com">info@maximumbc.com</a>
                  </div>
              <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyApupdRIqaFyLmcW0gTjCfY_mO9sOC8I_w" }}
                        defaultCenter={center}
                        defaultZoom={zoom}
                      >
                        <AnyReactComponent
                          lat={40.9234491}
                          lng={29.4155563}
                          text=<Image src={require('../../images/star.png')} />
                        />
                </GoogleMapReact>
            </div>
        );
    }
}
