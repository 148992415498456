import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class OurStory extends Component {
  state = { x: 0, y: 0 };
  handleMouseMove = (event) => {
    this.setState({
      x: event.clientX,
      y: event.clientY,
    });
  };

  render() {
    const { x, y } = this.state;
    return (
      <div id="container" style={{ backgroundColor: "#f5f5f5" }}>
        <Container id="ourStory" onMouseMove={this.handleMouseMove}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content ourStoryContent"
            >
              <h2>Bizim Hikayemiz;</h2>
              <p>
                Hikayemizi anlatmaya bir soruyla başlayalım istedik. Dünyanın en
                eski hikaye anlatıcılarını tanıyor musunuz? Bizce tanıyorsunuz.
                Belki az önce gördüğünüz banner’ı, izlediğiniz reklam filmini ya
                da filmde geçen markayı hatırlamıyorsunuz ama yaklaşık 6.000 yıl
                önce ortaya çıkan Güzel ve Çirkin’i, milattan önce 6. yüzyılda
                yaşadığı varsayılan Ezop’un masallarını, Adem’in elmayı
                ısırmasını, dinozorların yok oluşunu, destanları ve mitleri
                ezbere biliyorsunuz. Çünkü size iyi bir hikaye anlatıldı.
              </p>

              <p>Bizim hikayemiz tam da bu soruya cevap ararken başladı.</p>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image ourStory screen"
            >
              <Image
                src={require("../../images/stars.png")}
                alt="Bizim Hikayemiz"
                className="stars"
                style={{
                  left: x / 300,
                  top: y / 300,
                }}
              />

              <Image
                src={require("../../images/clouds.png")}
                alt="Bizim Hikayemiz"
                className="clouds"
                style={{
                  left: x / 25,
                  top: y / 20,
                }}
              />

              <Image
                src={require("../../images/line-01.png")}
                alt="Bizim Hikayemiz"
                style={{
                  width: 200,
                  position: "absolute",
                  marginTop: 520,
                  marginLeft: -180,
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-01.png")}
                alt="Bizim Hikayemiz"
                width="100%"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
