import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class WebSiteServices extends Component {
  state = { x: 0, y: 0 };
  handleMouseMove = (event) => {
    this.setState({
      x: event.clientX,
      y: event.clientY,
    });
  };

  render() {
    const { x, y } = this.state;

    return (
      <div id="container" style={{ backgroundColor: "#f5f5f5" }}>
        <Container id="webSiteServices" onMouseMove={this.handleMouseMove}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content webSiteServicesContent"
            >
              <h2>Web Sitesi Hizmetleri;</h2>
              <p>
                İyi hikayelerin bazı kodları vardır. Biz hem hikayeniz hem de
                onu anlatacak kodlar için çalışmaya hazırız. Gelin, hikayenizi
                tam da ihtiyacınıza uygun şekilde kodlayalım. SEO ve SEM
                dinamiklerine uygun, Responsive web sitesi, e-ticaret ve hosting
                ihtiyacınızı karşılayalım.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-09.png")}
                alt="Web Sitesi Hizmetleri"
                width="100%"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image webSiteServices screen"
            >
              <Image
                src={require("../../images/javascript.png")}
                alt="Web Sitesi Hizmetleri"
                className="javascript"
                style={{
                  left: x / 300,
                  top: y / 500,
                }}
              />

              <Image
                src={require("../../images/html.png")}
                alt="Web Sitesi Hizmetleri"
                className="html"
                style={{
                  left: x / 500,
                  top: y / 400,
                }}
              />

              <Image
                src={require("../../images/cplus.png")}
                alt="Web Sitesi Hizmetleri"
                className="cplus"
                style={{
                  left: x / 400,
                  top: y / 400,
                }}
              />

              <Image
                src={require("../../images/coffee.png")}
                alt="Web Sitesi Hizmetleri"
                className="coffee"
                style={{
                  left: x / 50,
                  top: y / 100,
                }}
              />

              <Image
                src={require("../../images/line-09.png")}
                alt="Web Sitesi Hizmetleri"
                style={{
                  width: 200,
                  position: "absolute",
                  marginTop: 550,
                  marginLeft: 50,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
