import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class HeroesOfOurStories extends Component {
  render() {
    return (
      <div id="container">
        <Container id="heroesOfOurStories">
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content heroesOfOurStoriesContent"
            >
              <h2>Hikayelerimizin Kahramanları;</h2>
              <p>
                Bizi en çok heyecanlandıran şey, yarattığımız hikayelerdir. Bir
                de siz bakın. Hikayelerimiz sizi de heyecanlandırıyorsa, vakit
                kaybetmeden iletişim sayfasına geçin.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-11.png")}
                alt="Hikayemizin Kahramanları"
                width="100%"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image heroesOfOurStories screen"
            >
              <Image
                src={require("../../images/maximum.png")}
                alt="Hikayemizin Kahramanları"
                className="maximum"
              />

              <Image
                src={require("../../images/line-11.png")}
                alt="Hikayemizin Kahramanları"
                style={{
                  width: 200,
                  position: "absolute",
                  marginTop: 520,
                  marginLeft: 320,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
