import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


console.log(`
 __________________     ____          ____     ____                ____
|                  |   |    |        |    |   |    |      ||      |    |
|______      ______|   |     |      |     |   |     |    |  |    |     |
       |    |          |      |    |      |   |      |  |    |  |      |
       |    |          |       |  |       |    |      ||      ||      |
       |    |          |        ||        |     |                    |
       |    |          |                  |      |        ||        |
       |    |          |    ||      ||    |       |      |  |      |
       |    |          |    | |    | |    |        |    |    |    |
       |    |          |    |  |  |  |    |         |  |      |  |
       |____|          |____|   ||   |____|          ||        ||

    BU WEB SİTESİ | TURKEY MEDIA WORKS | TARAFINDAN GELİŞTİRİLMİŞTİR

           www.turkeymediaworks.com - www.emrecelebi.com
`);
