import React from "react";
import Helmet from "react-helmet";
import Header from "./components/Header";
import Pages from "./components/Pages";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Maximum - Daha iyisi mümkün!</title>
        <link rel="canonical" href="http://maximumbc.com" />
        <meta
          name="description"
          content="Marka İletişimi alanında yeni öykülerle yeni çağın anlatım imkanlarını kreatif bir bakışla bir araya getiriyoruz. Dijital Varlıklarınızı incelememiz için bizimle iletişime geçebilirsiniz."
        />
      </Helmet>

      <Header />
      <Pages />
      <Footer />
    </div>
  );
}

export default App;
