import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default class MeetingPoint extends Component {
  state = { x: 0, y: 0 };
  handleMouseMove = (event) => {
    this.setState({
      x: 1500 - event.clientX,
    });
  };

  render() {
    const { x } = this.state;

    return (
      <div id="container" style={{ backgroundColor: "#f5f5f5" }}>
        <Container id="meetingPoint" onMouseMove={this.handleMouseMove}>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="content meetingPointContent"
            >
              <h2>Buluşma Noktamız;</h2>
              <p>
                Tolstoy, “Tüm muhteşem hikayeler iki şekilde başlar; ya bir
                insan bir yolculuğa çıkar ya da şehre bir yabancı gelir.” der.
                Tolstoy’a saygımız sonsuz! O yüzden siz bir yolculuğa çıkarak
                bize gelin ya da ofisinize bir yabancıyı yani bizleri davet
                edin. Birlikte bir kahve içelim, kahramanınızı sizden
                dinleyelim. Birlikte muhteşem hikayeler yazalım.
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="image mobile">
              <Image
                src={require("../../images/Maximum-12.png")}
                alt=""
                width="100%"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className="image meetingPoint screen"
            >
              <Image
                src={require("../../images/lamb-2.png")}
                alt=""
                className="lamb"
                style={{
                  transform: "rotate(-" + x / 50 + "deg)",
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
